
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout/layout';

export interface HomeProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {

}

const Home: FunctionComponent<HomeProps> = (props) => {

    const { t } = useTranslation();

    return (
        <Layout title={t('home.title')}>
            
        </Layout>
    )
}

export default Home

