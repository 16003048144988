
import { Panel as PrimePanel } from 'primereact/panel';
import React, { FunctionComponent } from 'react';

export interface PanelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    header?: string;
    headerTemplate?: JSX.Element; // higher priority than the 'label' property, if none specified no header is displayed
    toggleable?: boolean;
}

const Panel: FunctionComponent<PanelProps> = (props) => {

    const toggleable = props.toggleable != null ? props.toggleable : true;

    return (
        <PrimePanel header={props.header} headerTemplate={props.headerTemplate} toggleable={toggleable} >
            {props.children}
        </PrimePanel>
    )
}

export default Panel
