
import { Button as PrimeButton, ButtonPositionType as PrimeButtonPositionType } from 'primereact/button';
import React, { FunctionComponent, useState } from 'react';
import { Common } from '../../lib/common';
import ConfirmPopup from './confirm-popup';

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    label?: string;
    icon?: string;
    iconPos?: PrimeButtonPositionType | undefined;
    showConfirmation?: boolean;
    rounded?: boolean;
}

const Button: FunctionComponent<ButtonProps> = (props) => {

    const id = props.id ?? Common.uniqueId();

    const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false);
    const [clickEvent, setClickEvent] = useState<React.MouseEvent<HTMLButtonElement, MouseEvent>>();

    const click = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setClickEvent(e);

        if (!props.showConfirmation) {
            props.onClick && props.onClick(e);
            return;
        }

        setConfirmationVisible(true);
    }

    const accept = () => {
        setConfirmationVisible(false);
        props.onClick && props.onClick(clickEvent as React.MouseEvent<HTMLButtonElement, MouseEvent>);
    }

    const reject = () => {
        setConfirmationVisible(false);
    }

    let extraClasses = '';
    if (props.rounded) {
        extraClasses += ' p-button-rounded';
    }

    return (
        <>
            <PrimeButton id={id} label={props.label} className={(props.className != null ? props.className : '') + extraClasses}
                icon={props.icon} iconPos={props.iconPos} disabled={props.disabled}
                onClick={(e) => { click(e); }}>
                    {props.children && props.children}
                </PrimeButton>
            {
                props.showConfirmation &&
                <ConfirmPopup id={id}
                    visible={confirmationVisible}
                    accept={() => { accept(); }}
                    reject={() => { reject(); }}>
                </ConfirmPopup>
            }
        </>
    )
}

export default Button
