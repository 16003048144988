
import { DateTime } from "luxon";
import { Calendar as PrimeCalendar, CalendarChangeParams } from 'primereact/calendar';
import { FunctionComponent, useEffect, useState } from 'react';
import { IInputControlProps } from "../../interfaces/input-control-props.interface";
import { Common } from "../../lib/common";

export interface CalendarProps extends IInputControlProps {
    showTime?: boolean;
    showSeconds?: boolean;
    minDateTime?: string;
    maxDateTime?: string;
}

const dateFormat = 'dd/mm/yy';

const Calendar: FunctionComponent<CalendarProps> = (props) => {

    const id = Common.uniqueId();

    const showTime = props.showTime ?? true;
    const showSeconds = props.showSeconds ?? true;

    const date = DateTime.fromISO((props.value ?? '') as string).toJSDate();
    const [value, setValue] = useState<Date | Date[] | undefined>(date);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [touched, setTouched] = useState<boolean>(false);

    useEffect(() => {
        setValue(DateTime.fromISO((props.value ?? '') as string).toJSDate());
    }, [props.value])

    useEffect(() => {
        if (value != null && props.minDateTime != null && touched && DateTime.fromJSDate(value as Date) < DateTime.fromISO(props.minDateTime)) {
            setIsValid(false);
            return;
        }

        if (value != null && props.maxDateTime != null && touched && DateTime.fromJSDate(value as Date) > DateTime.fromISO(props.maxDateTime)) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    }, [value, props.required, touched, props.minDateTime, props.maxDateTime])

    const change = (e: CalendarChangeParams) => {
        setValue(e.value);
        setTouched(true);
        props.onValueChange && props.onValueChange(DateTime.fromJSDate(e.value as Date).toString());
    }

    return (
        <div className='field'>
            {
                props.label &&
                <label htmlFor={id} className="block">{props.label}</label>
            }
            <PrimeCalendar id={id} value={value}
                onChange={(e) => { change(e); }}
                onBlur={() => { props.onValueChange && props.onValueChange(DateTime.fromJSDate(value as Date).toString()); }}
                dateFormat={dateFormat}
                showTime={showTime}
                showSeconds={showSeconds}
                showIcon
                className={'w-full ' + (props.className != null ? props.className : '') + (!isValid ? ' p-invalid' : '')} />
        </div>
    )
}

export default Calendar
