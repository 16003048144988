
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/common/button';
import Label from '../../components/common/label';
import Title from '../../components/common/title';
import ListTile from '../../components/complex/list-tile';
import Layout from '../../components/layout/layout';
import { Common } from '../../lib/common';
import { Event, EventSession } from '../../lib/types';
import { selectList } from '../../store/selectors/eventsSelectors';
// import { selectList, thunkLoadList } from '../../store/features/events/eventsSlice';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Badge } from 'primereact/badge';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import Icons from '../../components/common/icons';
import { RootState, useAppDispatch } from '../../store/store';
import { fetchEvents } from '../../store/thunks/eventsThunks';

export interface EventListProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    events: Event[];
}

const EventList: FunctionComponent<EventListProps> = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([]);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    const [filters, setFilters] = useState({
        'global': { value: '', matchMode: FilterMatchMode.CONTAINS },
        'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    useEffect(() => {
        // dispatch(thunkLoadList());
        dispatch(fetchEvents());
    }, [dispatch]);

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between items-center">
                <h5 className="m-0">Events</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }

    const startDateBodyTemplate = (rowData: Event) => {
        return Common.formatDate(rowData.startDate);
    }

    const endDateBodyTemplate = (rowData: Event) => {
        return Common.formatDate(rowData.endDate);
    }

    const sessionStartDateBodyTemplate = (rowData: EventSession) => {
        return Common.formatDate(rowData.startDate);
    }

    const sessionEndDateBodyTemplate = (rowData: EventSession) => {
        return Common.formatDate(rowData.endDate);
    }

    const eventStatusTemplate = (rowData: Event) => {
        const today = new Date();
        const startDate = new Date(rowData.startDate as string);
        const endDate = new Date(rowData.startDate as string);

        let value = '';
        let severity = '';
        if (today < startDate) {
            value = 'Upcoming';
            severity = 'warning';
        }
        if (today >= startDate && today <= endDate) {
            value = 'In Progress';
            severity = 'success';
        }
        if (today > endDate) {
            value = 'Ended';
            severity = 'danger';
        }

        return (
            <Badge value={value} severity={severity as any}></Badge>
        );
    }

    const eventActionsTemplate = (rowData: Event) => {
        return (
            <Button onClick={() => { navigate(`/event/edit/${rowData.id}`) }} icon='pi pi-pencil'></Button>
        );
    }

    const sessionActionsTemplate = (rowData: EventSession) => {

        const event = props.events.find(event => event.sessions?.some(session => session.id === rowData.id));

        if (event == null) {
            return (<></>);
        }

        return (
            <Button onClick={(e) => { e.stopPropagation(); navigate(`/livemap/${event.id}/${rowData.id}`) }} icon='pi pi-play'></Button>
        );
    }

    const rowExpansionTemplate = (data: any) => {
        return (
            <div className="sessions-subtable">
                <h5>Sessions for {data.name}</h5>
                <DataTable value={data.sessions} responsiveLayout="scroll">
                    <Column field="name" header="Name" sortable></Column>
                    <Column field="startDate" header="Start Date" body={sessionStartDateBodyTemplate} sortable></Column>
                    <Column field="endDate" header="End Date" body={sessionEndDateBodyTemplate} sortable></Column>
                    <Column header="" body={sessionActionsTemplate}></Column>
                </DataTable>
            </div>
        );
    }

    return (
        <Layout title={t('event.list.title')} titleChildren={<Button onClick={() => { navigate(`/event/add`); }}>{t('common.create')}</Button>}>
            <div className='container mx-auto'>

                <DataTable value={props.events} header={renderHeader()} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={filters} filterDisplay="menu"
                    rowExpansionTemplate={rowExpansionTemplate} dataKey="id" responsiveLayout="scroll"
                    selectionMode="single" selection={selectedEvent} onSelectionChange={e => setSelectedEvent(e.value)}
                    globalFilterFields={['name', 'event.name', 'status']} emptyMessage="No events found."
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    rows={10} rowsPerPageOptions={[10, 25, 50]}>
                    <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
                    <Column expander style={{ width: '3em' }} />
                    <Column field="name" header="Name" sortable filter></Column>
                    <Column field="startDate" header="Start Date" body={startDateBodyTemplate} sortable filter></Column>
                    <Column field="endDate" header="End Date" body={endDateBodyTemplate} sortable filter></Column>
                    <Column header="Status" body={eventStatusTemplate} filter></Column>
                    <Column header="" body={eventActionsTemplate}></Column>
                </DataTable>

                <div className='grid grid-cols-4 gap-4' style={{ display: 'none' }}>
                    {
                        props.events && props.events.map((event) => {
                            return (
                                <ListTile key={event.id} onClick={() => { navigate(`/event/edit/${event.id}`) }}>
                                    <div className='flex flex-col justify-content-between content-center items-center w-full text-center'>
                                        <Title className='mb-4'>{event.name}</Title>

                                        {/* date */}
                                        <div className='mb-6'>
                                            {
                                                event.startDate &&
                                                <Label>{Common.formatDateTimeFull(new Date(event.startDate))}</Label>
                                            }
                                        </div>

                                        {/* sessions */}
                                        <ul className='flex flex-col justify-center items-center grow w-full space-y-2 list-none'>
                                            {
                                                event.sessions?.map((session) => {
                                                    return (
                                                        <li key={session.id} className='flex justify-content-between w-full p-2 bg-gray-200 cursor-pointer'
                                                            onClick={(e) => { e.stopPropagation(); navigate(`/livemap/${event.id}/${session.id}`) }}>
                                                            <Label>{session.name}</Label>
                                                            {
                                                                session.startDate &&
                                                                <Label>{Common.formatDateMonthTime(new Date(session.startDate))}</Label>
                                                            }
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                </ListTile>
                            );
                        })
                    }
                </div>

            </div>
        </Layout>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        events: selectList(state.events)
    }
}

export default connect(mapStateToProps)(EventList)

