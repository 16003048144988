import { LocationEntry } from './types';

var linear = require('everpolate').linear;

export class Interpolator {

    // the time difference between the current time's neighboor points beyond which interpolation should not be occured
    static interpolationTimeToleranceMillis: number = 5000;

    static linearInterpolation(point1: LocationEntry, point2: LocationEntry, t: number): Partial<LocationEntry> {
        return {
            id: 0,
            millis: linear(t, [0, 1], [point1.millis, point2.millis])[0],
            lat: linear(t, [0, 1], [point1.lat, point2.lat])[0],
            lgt: linear(t, [0, 1], [point1.lgt, point2.lgt])[0]
        }
    }

    /* ---------------leftFirstNeighborTime---------------currentTime---------------rightFirstNeighborTime--------------- */
    static canInterpolate(leftFirstNeighborTime: number | undefined, rightFirstNeighborTime: number | undefined, currentTime: number): boolean {
        return false;
        // return leftFirstNeighborTime == null
        //     || rightFirstNeighborTime == null
        //     || Math.abs(currentTime - leftFirstNeighborTime) > Interpolator.interpolationTimeToleranceMillis
        //     || Math.abs(rightFirstNeighborTime - currentTime) > Interpolator.interpolationTimeToleranceMillis
        //     ? false
        //     : true;
    }

    /* ---------------secondNeighborTime---------------firstNeighborTime---------------currentTime--------------- 
                                                                        OR
       ---------------currentTime---------------firstNeighborTime---------------secondNeighborTime--------------- */
    static canExtrapolate(firstNeighborTime: number | undefined, secondNeighborTime: number | undefined, currentTime: number): boolean {
        return false;
        // return firstNeighborTime == null
        //     || secondNeighborTime == null
        //     || Math.abs(currentTime - firstNeighborTime) > Interpolator.interpolationTimeToleranceMillis
        //     || Math.abs(firstNeighborTime - secondNeighborTime) > Interpolator.interpolationTimeToleranceMillis
        //     ? false
        //     : true;
    }

}