import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEvent, getEventRegistrations, getEvents, updateEvent } from "../../api/event-api";
import { Event } from '../../lib/types';

export const fetchEvents = createAsyncThunk(
    'events/fetchEvents',
    async () => {
        const events = await getEvents();
        return events;
    }
)

export const fetchEvent = createAsyncThunk(
    'events/fetchEvent',
    async (eventId: number) => {
        const event = await getEvent(eventId);
        return event;
    }
)

export const saveEvent = createAsyncThunk(
    'events/saveEvent',
    async (event: Event) => {
        const updatedEvent = await updateEvent(event);
        return updatedEvent;
    }
)

export const fetchEventRegistrations = createAsyncThunk(
    'events/fetchEventRegistrations',
    async (eventId: number) => {
        const eventRegistrations = await getEventRegistrations(eventId);
        return eventRegistrations;
    }
)