
import { MenuItem } from 'primereact/menuitem';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Navigation } from '../../lib/navigate';
import Menubar from '../common/menubar';

export interface NavbarProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {

}

const Navbar: FunctionComponent<NavbarProps> = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const items: MenuItem[] = [
        { label: t('navbar.home'), icon: 'pi pi-fw pi-home', command: () => { navigate(Navigation.home()); } },
        { label: t('navbar.events'), icon: 'pi pi-fw pi-list', command: () => { navigate(Navigation.eventsList()); } }
    ]

    return (
        <Menubar items={items} />
    )
}

export default Navbar

