import React, { FunctionComponent } from 'react';

export interface TableCellProps extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> {

}

const TableCell: FunctionComponent<TableCellProps> = (props) => {

    return (
        <td className={`p-2 ${props?.className && props.className}`}>
            {props.children}
        </td>
    )
}

export default TableCell
