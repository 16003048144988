
import { TabPanel as PrimeTabPanel } from 'primereact/tabview';
import React, { FunctionComponent } from 'react';

export interface TabPanelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    header: string;
}

const TabPanel: FunctionComponent<TabPanelProps> = (props) => {

    return (
        <></>
    )
}

export default TabPanel
