import React, { FunctionComponent } from 'react';
import { Common } from '../../lib/common';
import Label from '../common/label';
import Navbar from './navbar';

export interface LayoutProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    title?: string;
    titleChildren?: JSX.Element;
}

const Layout: FunctionComponent<LayoutProps> = (props) => {

    return (
        <>
            <Navbar />

            <main className="flex flex-column space-y-4 text-gray-600">
                {
                    (!Common.isNullOrEmpty(props.title) || props.titleChildren) &&
                    <div className='container flex justify-content-between items-center py-2'>
                        {
                            !Common.isNullOrEmpty(props.title) && <Label className='text-2xl'>{props.title}</Label>
                        }
                        {
                            props.titleChildren && props.titleChildren
                        }
                    </div>
                }

                <div>
                    {props.children}
                </div>
            </main>
        </>

    )
}

export default Layout

