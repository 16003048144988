import { Common } from "../lib/common";
import { Event, EventSession, LocationEntry } from "../lib/types";

export function eventToJson(event: Event): any {

    return {
        ...event, ...{
            startDate: !Common.isNullOrEmpty(event.startDate) ? new Date(event.startDate as string) : null,
            endDate: !Common.isNullOrEmpty(event.endDate) ? new Date(event.endDate as string) : null,
            sessions: event.sessions?.map(session => eventSessionToJson(session))
        }
    };
}

export function eventSessionToJson(eventSession: EventSession): any {

    return {
        ...eventSession, ...{
            startDate: !Common.isNullOrEmpty(eventSession.startDate) ? new Date(eventSession.startDate as string) : null,
            endDate: !Common.isNullOrEmpty(eventSession.endDate) ? new Date(eventSession.endDate as string) : null
        }
    };
}
