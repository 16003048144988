
import { Timeline as PrimeTimeline } from 'primereact/timeline';
import { FunctionComponent } from 'react';

export interface TimelineProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    value: any[];
    content: (item: any) => JSX.Element;
}

const customizedMarker = (item: any) => {
    return (
        <span className="custom-marker shadow-1" style={{ backgroundColor: 'rgb(255, 152, 0)' }}>
            <i className='pi pi-flag'></i>
        </span>
    );
};

const Timeline: FunctionComponent<TimelineProps> = (props) => {

    return (
        <PrimeTimeline value={props.value} content={props.content} marker={customizedMarker} align="alternate" />
    )
}

export default Timeline
