import React, { FunctionComponent } from 'react';

export interface TableRowProps extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {

}

const TableRow: FunctionComponent<TableRowProps> = (props) => {

    return (
        <tr className={`border-b ${props?.className && props.className}`}>
            {props.children}
        </tr>
    )
}

export default TableRow
