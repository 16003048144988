import { createAction } from "@reduxjs/toolkit";
import { Event, EventSession } from '../../lib/types';

export const loadListSuccess = createAction<Event[], 'loadListSuccess'>('loadListSuccess');
export const loadEventSuccess = createAction<Event, 'loadEventSuccess'>('loadEventSuccess');
export const saveEventSuccess = createAction<Event, 'saveEventSuccess'>('saveEventSuccess');

export const setEventName = createAction<string, 'setEventName'>('setEventName');
export const setEventStartDate = createAction<string, 'setEventStartDate'>('setEventStartDate');
export const setEventEndDate = createAction<string, 'setEventEndDate'>('setEventEndDate');
export const setEventCountry = createAction<string, 'setEventCountry'>('setEventCountry');
export const setEventLocation = createAction<string, 'setEventLocation'>('setEventLocation');
export const setEventLatitude = createAction<number, 'setEventLatitude'>('setEventLatitude');
export const setEventLongitude = createAction<number, 'setEventLongitude'>('setEventLongitude');
export const setEventSessions = createAction<EventSession[] | undefined, 'setEventSessions'>('setEventSessions');
