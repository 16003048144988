import React, { FunctionComponent } from 'react';

export interface TableProps extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement> {

}

const Table: FunctionComponent<TableProps> = (props) => {

    return (
        <table className={`table-auto w-full ${props?.className && props.className}`}>
            {props.children}
        </table>
    )
}

export default Table
