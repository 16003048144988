
import { ConfirmPopup as PrimeConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { FunctionComponent, useEffect, useState } from 'react';

export interface ConfirmPopupProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    id: string;
    visible?: boolean;
    accept?: () => void;
    reject?: () => void;
}

const ConfirmPopup: FunctionComponent<ConfirmPopupProps> = (props) => {

    const [visible, setVisible] = useState(props.visible);

    useEffect(() => {
        setVisible(props.visible);
    }, [props.visible])

    return (
        <PrimeConfirmPopup target={document.getElementById(props.id) as HTMLElement} visible={visible}
            onHide={() => setVisible(false)} message="Are you sure you want to proceed?"
            icon="pi pi-exclamation-triangle" accept={props.accept} reject={props.reject} />
    )
}

export default ConfirmPopup
