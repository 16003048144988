
import React, { FunctionComponent } from 'react';

export interface TitleProps extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {

}

const Title: FunctionComponent<TitleProps> = (props) => {

    return (
        <label {...props} className={`text-2xl ${props.className}`}>{props.children}</label>
    )
}

export default Title
