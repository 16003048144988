
import React, { FunctionComponent, useEffect, useReducer, useRef, useState } from 'react';
import { getEvent, getEventRegistrations, getLocationEntries } from '../../api/event-api';
import { Common } from '../../lib/common';
import { Interpolator } from '../../lib/interpolator';
import { Event, EventEntry, LocationEntry, RaceEntryType } from '../../lib/types';
import Button from '../common/button';
import Icons from '../common/icons';
import Map, { MapMarker, MapPolyline, MarkerType } from './map';

/*
const FRAMES_PER_SECOND = 1;
const TRAIL_LENGTH = 1;
const FETCH_LIVE_DATA_FREQUENCY = 1;
*/

const BASIC_FETCH_DATA_FREQUENCY = 5000;

export enum PlayerMode {
    Playback,
    Live
}
export interface PlayerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    eventId: number;
    sessionId: number;
}

enum PlayingSpeed {
    MinusX3 = 1,
    MinusX2,
    MinusX1,
    Zero,
    X1,
    X2,
    X3,
    Slider
}

const Player: FunctionComponent<PlayerProps> = (props) => {

    const refreshEvent = async () => {

        const evt = await getEvent(props.eventId);
        const evtRegistrations = await getEventRegistrations(props.eventId);

        const colors = Common.getNRandomColors(evtRegistrations.length);

        setEvent(evt);
        setRegistrations(evtRegistrations.map((registration) => {
            return { ...registration, ...{ color: colors.pop() } };
        }));
    }

    const initialize = async () => {

        if (event == null) {
            return;
        }

        const session = event.sessions?.find(s => s.id === props.sessionId);

        if (session == null) {
            console.error(`Session with id '${props.sessionId}' not found!`);
            return;
        }

        if (session.startDate == null) {
            console.error(`Session start date has not been set!`);
            return;
        }

        const mode = session.endDate == null || new Date(session.endDate) > new Date() ? PlayerMode.Live : PlayerMode.Playback;
        setPlayerMode(mode);

        const sessionStartTime = new Date(session.startDate)?.getTime();

        if (sessionStartTime > new Date().getTime()) {
            console.log(`Session has not started! Please wait.`);
            return;
        }

        setStartTime(sessionStartTime);
        setTime(mode === PlayerMode.Live ? new Date().getTime() - 10000 : sessionStartTime); // stay 10 seconds to the past to ensure server data existance

        if (session.endDate != null) {
            setEndTime(new Date(session.endDate)?.getTime());
        }
    }

    const fetchTrackPoints = async () => {

        // check if fetching data is necessary
        // if (props.mode === PlayerMode.Playback && timeRef.current - 5000 > locationEntriesStartTimeRef.current && timeRef.current + 5000 < locationEntriesEndTimeRef.current) {
        //     return;
        // }

        const offset = Math.round((timeRef.current - startTimeRef.current) / 1000);
        const data = await getLocationEntries(props.sessionId, offset, 15);

        // setLocationEntriesStartTime(startTimeRef.current + offset * 1000 - 120 * 1000);
        // setLocationEntriesEndTime(startTimeRef.current + offset * 1000 + 60 * 1000);

        // update the trajectories
        dispatchTrajectories({ type: 'UPDATE', payload: data });
    }

    const timeReducer = (state: number, action: { type: PlayingSpeed, payload: number }) => {
        switch (action.type) {
            case PlayingSpeed.MinusX3:
                return state - 3 * action.payload;
            case PlayingSpeed.MinusX2:
                return state - 2 * action.payload;
            case PlayingSpeed.MinusX1:
                return state - action.payload;
            case PlayingSpeed.Zero:
                return state;
            case PlayingSpeed.X1:
                return state + action.payload;
            case PlayingSpeed.X2:
                return state + 2 * action.payload;
            case PlayingSpeed.X3:
                return state + 3 * action.payload;
            case PlayingSpeed.Slider:
                return action.payload;
            default:
                throw new Error();
        }
    }

    const playingSpeedReducer = (state: PlayingSpeed, action: { type: 'RWD' | 'STOP' | 'PLAY' | 'FWD', mode: PlayerMode | undefined }) => {
        if (playerMode == null) {
            return state;
        }

        switch (action.type) {
            case 'RWD':
                if (action.mode === PlayerMode.Live) {
                    return state; // can't rewind while live playing
                }
                if (state === PlayingSpeed.MinusX3) {
                    return state;
                }
                return state <= PlayingSpeed.MinusX1 ? state - 1 : PlayingSpeed.MinusX1;
            case 'STOP':
                return PlayingSpeed.Zero;
            case 'PLAY':
                return PlayingSpeed.X1;
            case 'FWD':
                if (action.mode === PlayerMode.Live || state === PlayingSpeed.X3) { // can't fast forward while live playing
                    return state;
                }
                return state >= PlayingSpeed.X1 ? state + 1 : PlayingSpeed.X2;
            default:
                throw new Error();
        }
    }

    const trajectoriesReducer = (state: { [id: number]: LocationEntry[] }, action: { type: 'UPDATE', payload: LocationEntry[] }) => {
        switch (action.type) {
            case 'UPDATE':
                const updatedState: { [id: number]: LocationEntry[] } = {};

                const deviceIds = Common.distinct(action.payload.map(locationEntry => locationEntry.deviceId));

                for (let deviceId of deviceIds) {
                    const value = state[deviceId] ?? [];

                    const newLocationEntries = action.payload
                        .filter(locationEntry => locationEntry.deviceId === deviceId
                            && !value.some(existingLocationEntry => existingLocationEntry.id === locationEntry.id));

                    const mergedLocationEntries = [...value, ...newLocationEntries];

                    // sort by timestamp
                    mergedLocationEntries.sort((a: LocationEntry, b: LocationEntry) => {
                        if (a?.millis < b?.millis) {
                            return -1;
                        }
                        if (a?.timestamp > b?.timestamp) {
                            return 1;
                        }
                        return 0;
                    });

                    updatedState[deviceId] = mergedLocationEntries;
                }

                return updatedState;
            default:
                throw new Error();
        }
    }

    const startFetchDataInterval = () => {

        if (fetchDataIntervalRef.current != null) {
            clearInterval(fetchDataIntervalRef.current);
        }

        fetchDataIntervalRef.current = setInterval(() => {
            if (playingSpeedRef.current === PlayingSpeed.Zero) {
                return;
            }

            // refresh trajectories on interval
            // refreshLiveData();
            fetchTrackPoints();
        }, fetchDataFrequencyRef.current);

    }

    const [event, setEvent] = useState<Event>();
    const [registrations, setRegistrations] = useState<EventEntry[]>([]);

    const [playerMode, setPlayerMode] = useState<PlayerMode>();

    // session's start time
    const [startTime, setStartTime] = useState<number>(0);
    const startTimeRef = useRef(startTime);

    // session's end time
    const [endTime, setEndTime] = useState<number>(0);
    const endTimeRef = useRef(endTime);

    // player's present time
    const [time, dispatchTime] = useReducer(timeReducer, startTime ?? 0);
    const timeRef = useRef(time);

    // display present time
    const [timeDisplay, setTimeDisplay] = useState<string>(new Date(startTime ?? 0).toLocaleString());

    const [playingSpeed, dispatchPlayingSpeed] = useReducer(playingSpeedReducer, PlayingSpeed.Zero);
    const playingSpeedRef = useRef(playingSpeed);
    const [playingSpeedDisplay, setPlayingSpeedDisplay] = useState<string>();

    const fetchDataIntervalRef = useRef<NodeJS.Timer>();
    const fetchDataFrequencyRef = useRef(5000);

    const [trajectories, dispatchTrajectories] = useReducer(trajectoriesReducer, {});

    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const [polylines, setPolylines] = useState<MapPolyline[]>([]);

    // keep the ref objects in sync with the state
    useEffect(() => {
        timeRef.current = time;
        startTimeRef.current = startTime;
        endTimeRef.current = endTime;
        playingSpeedRef.current = playingSpeed;
    });

    useEffect(() => {
        refreshEvent();

        const refreshMapInterval: NodeJS.Timer = setInterval(() => {
            if (playingSpeedRef.current === PlayingSpeed.Zero) {
                return;
            }

            // increase time
            dispatchTime({ type: playingSpeedRef.current, payload: 250 });
            // fetchTrackPoints();
        }, 250);

        startFetchDataInterval();

        return () => {
            clearInterval(refreshMapInterval);
            if (fetchDataIntervalRef.current != null) {
                clearInterval(fetchDataIntervalRef.current);
            }
        };

    }, []);

    useEffect(() => {
        initialize();
    }, [event]);

    useEffect(() => {
        setTimeDisplay(new Date(time).toLocaleString());
        refreshFrame();
    }, [time]);

    useEffect(() => {

        switch (playingSpeed) {
            case PlayingSpeed.MinusX3:
            case PlayingSpeed.X3:
                fetchDataFrequencyRef.current = Math.round(BASIC_FETCH_DATA_FREQUENCY / 3);
                startFetchDataInterval();
                setPlayingSpeedDisplay('3');
                break;
            case PlayingSpeed.MinusX2:
            case PlayingSpeed.X2:
                fetchDataFrequencyRef.current = Math.round(BASIC_FETCH_DATA_FREQUENCY / 2);
                startFetchDataInterval();
                setPlayingSpeedDisplay('2');
                break;
            default:
                fetchDataFrequencyRef.current = Math.round(BASIC_FETCH_DATA_FREQUENCY);
                startFetchDataInterval();
                setPlayingSpeedDisplay('');
        }

        if (playingSpeed === PlayingSpeed.Zero) {
            return;
        }

        fetchTrackPoints();
    }, [playingSpeed]);

    const refreshFrame = () => {

        const updatedMarkers: MapMarker[] = [];
        const updatedPolylines: MapPolyline[] = [];

        for (let [key, value] of Object.entries(trajectories)) {
            const registration = registrations.find(r => r.deviceId === parseInt(key));

            // make a copy of the trajectory points
            const points = value.slice();
            // make a reversed order copy
            const reversedPoints = points.slice();
            reversedPoints.reverse();

            const leftFirstNeighbor = reversedPoints.find(point => point.millis <= timeRef.current);
            const rightFirstNeighbor = points.find(point => point.millis >= timeRef.current);
            const leftSecondNeighbor = leftFirstNeighbor != null ? reversedPoints[reversedPoints.indexOf(leftFirstNeighbor) + 1] : null
            const rightSecondNeighbor = rightFirstNeighbor != null ? points[points.indexOf(rightFirstNeighbor) - 1] : null

            // console.log(registration?.name, points, leftFirstNeighbor);


            let point: LocationEntry = points[points.length - 1];

            if (leftFirstNeighbor != null && rightFirstNeighbor != null
                && Interpolator.canInterpolate(leftFirstNeighbor?.millis, rightFirstNeighbor?.millis, timeRef.current)) {
                // do interpolation
                const t = (timeRef.current - leftFirstNeighbor?.millis) / (rightFirstNeighbor?.millis - leftFirstNeighbor?.millis);
                point = {
                    ...rightFirstNeighbor,
                    ...Interpolator.linearInterpolation(leftFirstNeighbor, rightFirstNeighbor, t)
                } as LocationEntry;
            } else if (leftFirstNeighbor != null && leftSecondNeighbor != null
                && Interpolator.canExtrapolate(leftFirstNeighbor?.millis, leftSecondNeighbor?.millis, timeRef.current)) {
                // do forward extrapolation
                const t = (timeRef.current - leftSecondNeighbor?.millis) / (leftFirstNeighbor?.millis - leftSecondNeighbor?.millis);
                point = {
                    ...leftFirstNeighbor,
                    ...Interpolator.linearInterpolation(leftSecondNeighbor, leftFirstNeighbor, t)
                } as LocationEntry;
            } else if (Interpolator.canExtrapolate(rightFirstNeighbor?.millis, rightSecondNeighbor?.millis, timeRef.current)) {
                // do backward extrapolation
            }

            updatedMarkers.push({
                lat: point.lat as number,
                long: point.lgt as number,
                tooltip: registration?.name,
                type: registration?.type === RaceEntryType.Contestant ? MarkerType.Boat : MarkerType.Mark,
                color: registration?.color
            });

            // find the latest n seconds to display the trail
            const trailTimeInSeconds = 10;
            const trailPoints = points.filter(p => p.millis <= time && p.millis > (time - trailTimeInSeconds * 1000));

            trailPoints.reverse();
            trailPoints.unshift(point);
            updatedPolylines.push({ points: trailPoints.map(point => [point.lat, point.lgt]), color: registration?.color });
        }

        // console.log('updatedMarkers ', updatedMarkers);
        // console.log('updatedPolylines ', updatedPolylines);

        setMarkers(updatedMarkers);
        setPolylines(updatedPolylines);
    }

    const setTime = (timestamp: number) => {
        dispatchTime({ type: PlayingSpeed.Slider, payload: timestamp });
    }

    return (
        <div className='space-y-4'>
            {/* toolbar */}
            <div className='flex justify-end items-center space-x-4'>

                {/* control buttons */}
                <div className='flex items-center space-x-2'>
                    <Button icon='pi pi-fast-backward' onClick={() => { dispatchPlayingSpeed({ type: 'RWD', mode: playerMode }); }} disabled={playerMode === PlayerMode.Live || playingSpeed === PlayingSpeed.MinusX3}></Button>
                    <Button icon='pi pi-pause' onClick={() => { dispatchPlayingSpeed({ type: 'STOP', mode: playerMode }); }} disabled={playingSpeed === PlayingSpeed.Zero}></Button>
                    <Button icon='pi pi-play' onClick={() => { dispatchPlayingSpeed({ type: 'PLAY', mode: playerMode }); }} disabled={playingSpeed === PlayingSpeed.X1}></Button>
                    <Button icon='pi pi-fast-forward' onClick={() => { dispatchPlayingSpeed({ type: 'FWD', mode: playerMode }); }} disabled={playerMode === PlayerMode.Live || playingSpeed === PlayingSpeed.X3}></Button>
                    <span className='flex justify-center items-center w-14 p-2 bg-gray-200'>
                        {playingSpeed === PlayingSpeed.Zero && <Icons.Minus />}
                        {playingSpeed < PlayingSpeed.Zero && <Icons.ChevronLeft />}
                        {playingSpeedDisplay}
                        {playingSpeed > PlayingSpeed.Zero && <Icons.ChevronRight />}
                    </span>
                </div>

                {/* time slider */}
                <div className='flex items-center grow'>
                    <input type='range' className='w-full' min={startTime} max={endTime} step={1} value={time}
                        onChange={(event) => { setTime(parseInt(event.target.value)); playerMode === PlayerMode.Live && dispatchPlayingSpeed({ type: 'STOP', mode: playerMode }); }} />
                </div>

                {/* clock */}
                {/* <Clock startTime={startTime} tick={() => { nextFrame() }} /> */}
                <div>
                    <h1>{timeDisplay}</h1>
                </div>
                <div>
                    {
                        playerMode === PlayerMode.Live
                            ? <span className='p-2 bg-red-500 text-white'>Live</span>
                            : <span className='p-2 bg-green-500 text-white'>Playback</span>
                    }
                </div>
            </div>
            {
                event &&
                <div className='flex'>
                    <div className='w-[calc(100%_-_20rem)] h-[calc(100vh_-_123px)]'>
                        <Map center={{ latitude: event?.latitude ?? 37.65297050212606, longitude: event?.longtitude ?? 24.02709798431732 }} markers={markers} polylines={polylines} autoCenter={true} />
                    </div>

                    {/* leaderboard */}
                    <div className='w-[20rem] p-4 bg-gray-200'>
                        {
                            registrations && registrations.filter(registration => registration.type === RaceEntryType.Contestant).map((registration) => {
                                return (
                                    <div key={registration.id} className='flex justify-content-between items-center p-2'>
                                        <span>{registration.name}</span>
                                        <span style={{ backgroundColor: registration.color }} className='inline-block w-20 h-1'></span>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            }

        </div>
    )
}

export default Player

