import { DateTime } from "luxon";
export class Common {

    static uniqueId(): string {
        return 'idxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static isEmailValid(email: string | undefined): boolean {
        if (email == null) {
            return false;
        }

        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) != null;
    }

    static isPasswordValid(password: string | undefined): boolean {
        if (password == null) {
            return false;
        }

        // todo: make it more strictly
        // minimum 6 characters, no spaces
        return password.length >= 6 && password.indexOf(' ') < 0;
    }

    static isNullOrEmpty(str: string | null | undefined) {
        return str == null || str.trim().length <= 0;
    }

    static isNullOrTrue(value: boolean | undefined) {
        return value == null || value === true;
    }

    static isNullOrFalse(value: boolean | undefined) {
        return value == null || value === false;
    }

    static intersection(array1: any[], array2: any[]) {
        return array1.filter(value => array2.includes(value));
    }

    static isNullOrEmptyObject(obj: any) {
        return obj == null || Object.keys(obj).length === 0;
    }

    static distinct(items: any[]): any[] {
        return Array.from(new Set(items))
    }

    static commonElements(arrays: any[][]): any[] {
        if (arrays == null) {
            return [];
        }

        return arrays.shift()?.reduce(function (res, v) {
            if (res.indexOf(v) === -1 && arrays.every(function (a) {
                return a.indexOf(v) !== -1;
            })) res.push(v);
            return res;
        }, []);
    }

    static isValidDate(date: string): boolean {
        // const parsedDate = parse(date, `yyyy-MM-dd'T'hh:mm:ss.SSS`, new Date());
        // return isValid(parsedDate);
        return true;
    }

    static toDatetimeLocal(date: Date): string {
        // if (date == null) {
        //     return '';
        // }
        // // '2023-04-05T23:00:57.403'
        // return format(new Date(date), `yyyy-MM-dd'T'HH:mm:ss.SSS`);
        return DateTime.fromJSDate(date).toLocaleString();
    }

    static formatDateTimeFull(date: Date): string {
        // if (date == null) {
        //     return '';
        // }
        // // '2023-04-05T23:00:57.403'
        // return format(new Date(date), `dd-MM-yyyy HH:mm`);
        return DateTime.fromJSDate(date).toLocaleString();
    }

    static formatDateMonthTime(date: Date): string {
        // if (date == null) {
        //     return '';
        // }
        // // '2023-04-05T23:00:57.403'
        // return format(new Date(date), `dd-MM HH:mm`);
        return DateTime.fromJSDate(date).toLocaleString();
    }

    static formatDate(date: Date | string | undefined | null) {
        // if (date == null) {
        //     return date;
        // }
        // return moment(date).format('LLL');
        return date;
    }

    static getNRandomColors(n: number): string[] {

        const colorsArr = [
            '#e6194B', 
            '#f58231', 
            '#ffe119', 
            '#bfef45', 
            '#3cb44b', 
            '#42d4f4', 
            '#4363d8', 
            '#911eb4', 
            '#f032e6', 
            '#a9a9a9', 
            '#800000', 
            '#9A6324', 
            '#808000', 
            '#469990', 
            '#000075', 
            '#000000', 
            '#fabed4', 
            '#ffd8b1', 
            '#fffac8', 
            '#aaffc3', 
            '#dcbeff' 
        ];

        return colorsArr.slice(0, n);
    }

    static formatDateNew(date: string | null | undefined): string {
        if (date == null || date.trim().length <= 0) {
            return '';
        }
        return DateTime.fromISO(date).toFormat('dd/MM/yyyy HH:mm:ss').toString();
    }

}