import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import EventEdit from './pages/event/event-edit';
import EventList from './pages/event/event-list';
import Home from './pages/home';
import LiveMap from './pages/live-map';

import "primereact/resources/themes/tailwind-light/theme.css";      //theme
import "primereact/resources/primereact.min.css";                   //core css
import "primeicons/primeicons.css";                                 //icons
import "primeflex/primeflex.min.css";                               //primeflex

function App() {

  return (
    <>
      <Suspense fallback="loading">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/livemap/:eventId/:sessionId" element={<LiveMap />} />
          <Route path="/event/list" element={<EventList />} />
          <Route path="/event/add" element={<EventEdit />} />
          <Route path="/event/edit/:id" element={<EventEdit />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App
