
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Player from '../components/complex/player';
import Layout from '../components/layout/layout';
export interface LiveMapProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {

}

const LiveMap: FunctionComponent<LiveMapProps> = (props) => {

    const { t } = useTranslation();
    let { eventId, sessionId } = useParams();

    return (
        <Layout>
            <div>
                {
                    eventId && sessionId && <Player eventId={parseInt(eventId)} sessionId={parseInt(sessionId)} />
                }

            </div>
        </Layout>
    )
}

export default LiveMap

