import React, { FunctionComponent } from 'react';

export interface TableHeadCellProps extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement> {

}

const TableHeadCell: FunctionComponent<TableHeadCellProps> = (props) => {

    return (
        <td className={`p-2 text-left ${props?.className && props.className}`}>
            {props.children}
        </td>
    )
}

export default TableHeadCell
