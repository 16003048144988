
export default async function fetchJson(input: RequestInfo, init?: RequestInit | undefined) {

    init = {
        ...{
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }, ...init
    };

    try {
        const response = await fetch(input, init);

        const contentType = response.headers.get("content-type");

        let data = null;
        if (contentType != null) {
            // if the server replies, there's always some data in json
            // if there's a network error, it will throw at the previous line
            data = await response.json();
        }

        if (response.ok) {
            return data;
        }

        const error = { ...(new Error(response.statusText)), ...{ response, data } };
        throw error;
    } catch (error: any) {
        if (!error.data) {
            error.data = { message: error.message };
        }
        throw error;
    }
}