import L from "leaflet";
import React from "react";
import { Marker, useMap } from "react-leaflet";

const getIcon = (color) => {

  color = color ?? '#000';

  const markerHtmlStyle = `
    position: relative;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${color};
    border-radius: 100%;
    border: 1px solid #fff`;

  return L.divIcon({
    className: "dot-pin",
    iconAnchor: [8, 8],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<span style="${markerHtmlStyle}" />`
  })
}

export default function DotMarker(props) {
  const map = useMap();

  const icon = getIcon(props.color);

  return (
    <div>
      <Marker
        icon={icon}
        position={[props.lat, props.lng]}
        eventHandlers={{
          click: (e) => {
            map.flyTo(e.latlng, 14);
          },
        }}
      ></Marker>
    </div>
  );
}