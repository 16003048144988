
import { Menubar as PrimeMenubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import React, { FunctionComponent } from 'react';

export interface MenubarProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    items: MenuItem[];
}

const Menubar: FunctionComponent<MenubarProps> = (props) => {

    return (
        <PrimeMenubar model={props.items} />
    )
}

export default Menubar
