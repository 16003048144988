
import { Dialog as PrimeDialog } from 'primereact/dialog';
import { FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import Button from './button';

export interface DialogProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    header: string;
    visible?: SetStateAction<boolean>;
    onHide?: (result: boolean) => void;
    hasOkButton?: boolean;
    hasCancelButton?: boolean;
    okButtonLabel?: string;
    cancelButtonLabel?: string;
}

const Dialog: FunctionComponent<DialogProps> = (props) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(props.visible === true);
    }, [props.visible])


    const onHide = (result: boolean) => {
        setVisible(false);
        props.onHide && props.onHide(result);
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label={props.cancelButtonLabel ?? 'No'} icon="pi pi-times" onClick={() => onHide(false)} className="p-button-text" />
                <Button label={props.okButtonLabel ?? 'Yes'} icon="pi pi-check" onClick={() => onHide(true)} autoFocus />
            </div>
        );
    }

    return (
        <>
            <PrimeDialog header={props.header} visible={visible} style={{ width: '50vw' }} footer={renderFooter()} onHide={() => onHide(false)} maximizable>
                {props.children}
            </PrimeDialog>
        </>
    )
}

export default Dialog
