
import { InputNumber as PrimeInputNumber } from 'primereact/inputnumber';
import { FunctionComponent, useEffect, useState } from 'react';
import { IInputControlProps } from '../../interfaces/input-control-props.interface';
import { Common } from '../../lib/common';

export interface InputNumberProps extends IInputControlProps {

}

const InputNumber: FunctionComponent<InputNumberProps> = (props) => {

    const id = Common.uniqueId();

    const number = parseInt(props.value as string);
    const [value, setValue] = useState<number | undefined>(number);

    useEffect(() => {
        setValue(parseFloat(props.value as string));
    }, [props.value])

    return (
        <div className='field'>
            {
                props.label &&
                <label htmlFor={id} className="block">{props.label}</label>
            }
            <PrimeInputNumber id={id} value={value}
                onChange={(e) => { setValue(e.value as number | undefined); }}
                onBlur={() => { props.onValueChange && props.onValueChange(value); }}
                maxFractionDigits={10}
                className="w-full" />
        </div>
    )
}

export default InputNumber
