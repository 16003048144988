import React, { FunctionComponent } from 'react';

export interface TableHeadRowProps extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement> {

}

const TableHeadRow: FunctionComponent<TableHeadRowProps> = (props) => {

    return (
        <tr className={`border-b-2 ${props?.className && props.className}`}>
            {props.children}
        </tr>
    )
}

export default TableHeadRow
