import { createSelector, createDraftSafeSelector } from "@reduxjs/toolkit"
import { EventsState } from "../reducers/eventsReducer"

const selectSelf = (state: EventsState) => state

// const unsafeSelector = createSelector(selectSelf, (state) => state)
// const draftSafeSelector = createDraftSafeSelector(
//   selectSelf,
//   (state) => state
// )

export const selectList = createSelector(selectSelf, state => state.list);
export const selectEvent = createSelector(selectSelf, state => state.event);
export const selectEventRegistrations = createSelector(selectSelf, state => state.eventRegistrations);