
import { TabPanel as PrimeTabPanel, TabView as PrimeTabView } from 'primereact/tabview';
import React, { Children, FunctionComponent, useEffect, useState } from 'react';

export interface TabViewProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    onTabChange?: (tabIndex: number) => void;
}

const TabView: FunctionComponent<TabViewProps> = (props) => {

    const arrayChildren: any[] = Children.toArray(props.children);

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        props.onTabChange && props.onTabChange(activeIndex);
    }, [props, activeIndex]);

    return (
        <PrimeTabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            {
                Children.map(arrayChildren, (child, index) => {
                    return (
                        <PrimeTabPanel header={child.props.header}>
                            {child.props.children}
                        </PrimeTabPanel>
                    );
                })
            }
        </PrimeTabView>
    )
}

export default TabView
