
import React, { FunctionComponent } from 'react';

export interface ListTileProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {

}

const ListTile: FunctionComponent<ListTileProps> = (props) => {

    return (
        <div className='flex justify-center p-10 bg-gray-100' {...props}>
            {props.children}
        </div>
    )
}

export default ListTile
