
import { MultiSelect as PrimeMultiSelect, MultiSelectChangeParams } from 'primereact/multiselect';
import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { Common } from '../../lib/common';

export interface MultiSelectProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    options: any[];
    value: any[];
    label?: string;
}

const MultiSelect: FunctionComponent<MultiSelectProps> = (props) => {

    const id = Common.uniqueId();

    const [value, setValue] = useState<any>(props.value);

    const onChange = (event: MultiSelectChangeParams) => {
        setValue(event.value);
        console.log(event);
        props.onChange && props.onChange(event as any as ChangeEvent<HTMLInputElement>);
    }

    return (
        <div className='field'>
            {
                props.label &&
                <label htmlFor={id} className="block">{props.label}</label>
            }
            <PrimeMultiSelect id={id} value={value} onChange={(e: MultiSelectChangeParams) => { onChange(e) }}
                display="chip" optionLabel="label" optionValue="value" options={props.options} />
        </div>
    )
}

export default MultiSelect
