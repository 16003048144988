export type Event = {
    id: number,
    name: string,
    startDate?: string,
    endDate?: string,
    country: string,
    location: string,
    latitude?: number,
    longtitude?: number,
    imagePath: string,
    sessions?: EventSession[]
}

export type EventSession = {
    id: number,
    name: string,
    startDate?: string,
    endDate?: string
}

export type EventEntry = {
    id: number,
    name: string,
    code: string,
    type: RaceEntryType,
    deviceId: number,
    sessions?: number[],
    color?: string
}

export enum RaceEntryType {
    Contestant,
    Boundry,
    Other
}

export type LocationEntry = {
    id: number,
    deviceId: number
    lat: number,
    lgt: number,
    timestamp: string,
    spd?: number,
    millis: number
}

export type Device = {
    id: number,
    deviceId: string
}
