
import { Session } from 'inspector';
import { DateTime, Interval } from 'luxon';
import { MultiSelectChangeParams } from 'primereact';
import { Badge } from 'primereact/badge';
import { Card } from 'primereact/card';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addEvent, addEventRegistration, deleteEventRegistration, updateEvent, updateEventRegistration } from '../../api/event-api';
import Button from '../../components/common/button';
import Calendar from '../../components/common/calendar';
import DataTable, { Column } from '../../components/common/data-table';
import Dialog from '../../components/common/dialog';
import Icons from '../../components/common/icons';
import InputNumber from '../../components/common/input-number';
import InputText from '../../components/common/input-text';
import Label from '../../components/common/label';
import MultiSelect from '../../components/common/multi-select';
import Panel from '../../components/common/panel';
import Select, { SelectOption } from '../../components/common/select';
import Table from '../../components/common/table/table';
import TableCell from '../../components/common/table/table-cell';
import TableHeadCell from '../../components/common/table/table-head-cell';
import TableHeadRow from '../../components/common/table/table-head-row';
import TableRow from '../../components/common/table/table-row';
import TabPanel from '../../components/common/Tabs/tab-panel';
import TabView from '../../components/common/Tabs/tab-view';
import Timeline from '../../components/common/timeline';
import Layout from '../../components/layout/layout';
import { Common } from '../../lib/common';
import { Event, EventEntry, EventSession, RaceEntryType } from '../../lib/types';
import { setEventCountry, setEventEndDate, setEventLatitude, setEventLocation, setEventLongitude, setEventName, setEventSessions, setEventStartDate } from '../../store/actions/eventsActions';
import { selectEvent, selectEventRegistrations } from '../../store/selectors/eventsSelectors';
import { RootState, useAppDispatch } from '../../store/store';
import { fetchEvent, fetchEventRegistrations, saveEvent } from '../../store/thunks/eventsThunks';

const humanizeDuration = require("humanize-duration");

export interface EventEditProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    event: Event | null;
    eventRegistrations?: EventEntry[];
}

enum Tab {
    Main,
    Sessions,
    Participant, Mark
}

const EventEdit: FunctionComponent<EventEditProps> = (props) => {

    const { t } = useTranslation();
    let { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [startDate, setStartDate] = useState<DateTime>(DateTime.fromISO(props.event?.startDate ?? ''));
    const [endDate, setEndDate] = useState<DateTime>(DateTime.fromISO(props.event?.endDate ?? ''));
    const [errMessage, setErrMessage] = useState<string>('');

    const [activeTab, setActiveTab] = useState<Tab>(Tab.Main);

    useEffect(() => {
        if (id == null || parseInt(id as string) <= 0) {
            return;
        }
        dispatch(fetchEvent(parseInt(id as string)));
        dispatch(fetchEventRegistrations(parseInt(id as string)));
    }, [id, dispatch]);

    const [event, setEvent] = useState<Event>(props.event ?? {
        id: 0,
        name: '',
        startDate: DateTime.local().toISO(),
        endDate: DateTime.local().plus({ hours: 2 }).toISO(),
        country: '',
        location: '',
        latitude: 0,
        longtitude: 0,
        imagePath: '',
        sessions: []
    });

    const eventDuration = Interval
        .fromDateTimes(startDate, endDate)
        .toDuration()
        .valueOf();

    const [eventRegistrations, setEventRegistrations] = useState<EventEntry[]>(props.eventRegistrations ?? []);

    useEffect(() => {
        if (props.event == null) {
            return;
        }
        setEvent(props.event);
        setStartDate(DateTime.fromISO(props.event?.startDate ?? ''));
        setEndDate(DateTime.fromISO(props.event?.endDate ?? ''));

    }, [props.event]);

    useEffect(() => {
        if (props.eventRegistrations == null) {
            return;
        }
        setEventRegistrations(props.eventRegistrations);
    }, [props.eventRegistrations]);

    useEffect(() => {
        validateEvent();
    }, [event]);

    // const addSession = () => {
    //     setEvent({ ...event, ...{ sessions: [...event.sessions ?? [], ...[{ id: 0, name: '' }]] } });
    // }

    const validateEvent = () => {
        setErrMessage('');

        var startDate = DateTime.fromISO(props.event?.startDate ?? '');
        var endDate = DateTime.fromISO(props.event?.endDate ?? '');

        if (startDate > endDate) {
            setErrMessage(`End date can't be before start date`);
            return;
        }

        //40.50898862557296, 19.29361460163708
        //34.981321502813785, 28.001913306030005

        if (event.latitude == null) {
            setErrMessage(`Latitude is not defined`);
            return;
        }

        if (event.longtitude == null) {
            setErrMessage(`Longitude is not defined`);
            return;
        }

        if ((event.latitude ?? 0) < 33 || (event.latitude || 0) > 42) {
            setErrMessage(`Latitude out of range`);
            return;
        }

        if ((event.longtitude ?? 0) < 18 || (event.longtitude || 0) > 30) {
            setErrMessage(`Longitude out of range`);
            return;
        }

    }

    const patchSession = (session: EventSession, patch: Partial<EventSession>) => {

        const index = event.sessions?.indexOf(session);

        if (index == null || index < 0) {
            console.error('Event Session not found!');
            return;
        }

        setEvent({ ...event, ...{ sessions: Object.assign([], event.sessions, { [index]: { ...session, ...patch } }) } });
    }

    const addRegistration = (type: RaceEntryType) => {
        setEventRegistrations([...eventRegistrations, ...[
            {
                id: 0,
                name: '',
                code: '',
                type,
                deviceId: 0
            }
        ]]);
    }

    const patchRegistration = (eventEntry: EventEntry, patch: Partial<EventEntry>) => {

        const index = eventRegistrations?.indexOf(eventEntry);

        if (index == null || index < 0) {
            console.error('Event Registration not found!');
            return;
        }

        setEventRegistrations(Object.assign([], eventRegistrations, { [index]: { ...eventEntry, ...patch } }));
    }

    const saveRegistration = async (eventEntry: EventEntry) => {
        const index = eventRegistrations?.indexOf(eventEntry);

        if (index == null || index < 0) {
            console.error('Event Registration not found!');
            return;
        }

        const updatedEventRegistration = eventEntry.id > 0 ? await updateEventRegistration(eventEntry) : await addEventRegistration(eventEntry, event.id);

        setEventRegistrations(Object.assign([], eventRegistrations, { [index]: updatedEventRegistration }));
    }

    const deleteRegistration = async (eventEntry: EventEntry) => {

        // todo: confirmation

        const index = eventRegistrations?.indexOf(eventEntry);

        if (index == null || index < 0) {
            console.error('Event Registration not found!');
            return;
        }

        if (eventEntry.id > 0) {
            const deletionRes = await deleteEventRegistration(eventEntry.id);

            if (deletionRes == null) {
                setEventRegistrations(eventRegistrations.filter((val, i, arr) => { return i !== index }));
            }
        }

    }

    const handleSave = async () => {

        // existing event, call update
        if (event.id > 0) {
            const updatedEvent = await updateEvent(event);
            setEvent(updatedEvent);
            return;
        }

        // new event, call add
        const addedEvent = await addEvent(event);
        setEvent(addedEvent);
    }

    // const save = () => {
    //     if (event == null) {
    //         return;
    //     }

    //     dispatch(saveEvent(event));
    // }

    const onTabChange = (index: number) => {
        setActiveTab(index);
    }

    return (
        <Layout titleChildren={<>
            {(activeTab === Tab.Main || activeTab === Tab.Sessions) &&
                <Button label={t('common.save')} onClick={(e) => { handleSave(); }} icon='pi pi-check' className='p-button-success' disabled={!Common.isNullOrEmpty(errMessage)} />
            }
        </>
        } title={t('event.edit.title')}>
            {/* <EventComponent event={props.event} eventRegistrations={[]} /> */}

            <div className='container mx-auto'>
                <TabView onTabChange={onTabChange}>
                    {/* main info */}
                    <TabPanel header={t('event.component.mainInfo')}>
                        <div className='grid'>
                            <div className='sm:col-12 xl:col-6'>
                                <Panel header={t('event.component.mainInfo')}>
                                    <div className='grid'>
                                        <div className='sm:col-12'>
                                            <InputText value={event?.name} label={t('common.name')}
                                                onValueChange={(value) => { dispatch(setEventName((value ?? '').toString())); }} />
                                        </div>
                                        <div className='sm:col-12 md:col-6'>
                                            <Calendar value={event?.startDate ?? ''} label={t('event.component.startDate')}
                                                onValueChange={(value) => { dispatch(setEventStartDate(value as string)); }} />
                                        </div>
                                        <div className='sm:col-12 md:col-6'>
                                            <Calendar value={event?.endDate ?? ''} label={t('event.component.endDate')}
                                                onValueChange={(value) => { dispatch(setEventEndDate(value as string)); }} />
                                        </div>
                                        <div className='sm:col-12 md:col-12'>
                                            <Label>{t('event.component.eventDuration')}: </Label>
                                            {humanizeDuration(eventDuration, { language: 'gr' })}
                                        </div>
                                        <div className='sm:col-12 md:col-6'>
                                            <InputText value={event?.country} label={t('event.component.country')}
                                                onValueChange={(value) => { dispatch(setEventCountry(value as string)); }} />
                                        </div>
                                        <div className='sm:col-12 md:col-6'>
                                            <InputText value={event?.location} label={t('event.component.location')}
                                                onValueChange={(value) => { dispatch(setEventLocation(value as string)); }} />
                                        </div>
                                        <div className='sm:col-12 md:col-6'>
                                            <InputNumber value={event?.latitude ?? 0} label={t('event.component.latitude')}
                                                onValueChange={(value) => { dispatch(setEventLatitude(value as any as number)); }} />
                                        </div>
                                        <div className='sm:col-12 md:col-6'>
                                            <InputNumber value={event?.longtitude ?? 0} label={t('event.component.longitude')}
                                                onValueChange={(value) => { dispatch(setEventLongitude(value as any as number)); }} />
                                        </div>
                                    </div>
                                    {!Common.isNullOrEmpty(errMessage) &&
                                        <span className='text-red-600'>{errMessage}</span>
                                    }
                                </Panel>
                            </div>

                        </div>
                    </TabPanel>
                    {/* sessions */}
                    <TabPanel header={t('event.component.sessions')}>
                        <div className='sm:col-12 xl:col-8'>
                            {
                                event.id > 0 &&
                                <Panel header={t('event.component.sessions')} className='col-span-2'>
                                    <EventSessions sessions={event.sessions} event={event} registrations={eventRegistrations}></EventSessions>
                                </Panel>
                            }

                        </div>
                    </TabPanel>
                    {
                        event.id > 0 &&
                        <TabPanel header={t('event.component.participants')}>
                            {/* participants */}
                            <Panel header={t('event.component.participants')}>
                                <Table>
                                    <thead>
                                        <TableHeadRow>
                                            <TableHeadCell>Id</TableHeadCell>
                                            <TableHeadCell>{t('common.name')}</TableHeadCell>
                                            <TableHeadCell>{t('event.component.code')}</TableHeadCell>
                                            <TableHeadCell>{t('event.component.device')}</TableHeadCell>
                                            <TableHeadCell>Sessions</TableHeadCell>
                                            <TableHeadCell></TableHeadCell>
                                        </TableHeadRow>
                                    </thead>
                                    <tbody>
                                        {
                                            eventRegistrations &&
                                            eventRegistrations.filter(eventRegistration => eventRegistration.type === RaceEntryType.Contestant).map((eventRegistration) => {
                                                return (
                                                    <TableRow key={eventRegistrations?.indexOf(eventRegistration)}>
                                                        <TableCell>
                                                            {eventRegistration.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            <InputText value={eventRegistration.name}
                                                                onValueChange={(value) => { patchRegistration(eventRegistration, { name: value as string }) }} className='w-full' />
                                                        </TableCell>
                                                        <TableCell>
                                                            <InputText value={eventRegistration.code}
                                                                onValueChange={(value) => { patchRegistration(eventRegistration, { code: value as string }) }} className='w-full' />
                                                        </TableCell>
                                                        <TableCell>
                                                            <InputNumber value={eventRegistration.deviceId ?? 0}
                                                                onValueChange={(value) => { patchRegistration(eventRegistration, { deviceId: value as number }); }} className='w-full' />
                                                        </TableCell>
                                                        <TableCell>
                                                            {/* <Select value={eventRegistration.sessions as any[]} options={event.sessions?.map(session => {
                                                        return { value: session.id, label: session.name };
                                                    }) as SelectOption[]} onChange={(e) => { patchRegistration(eventRegistration, { sessions: Array.from(e.target.selectedOptions, option => parseInt(option.value)) }) }} multiple /> */}
                                                            <MultiSelect value={eventRegistration.sessions as any[]} options={event.sessions?.map(session => {
                                                                return { value: session.id, label: session.name };
                                                            }) as SelectOption[]} onChange={(e) => { patchRegistration(eventRegistration, { sessions: (e as any as MultiSelectChangeParams).value }) }} />
                                                        </TableCell>
                                                        <TableCell className='w-0'>
                                                            <div className='flex justify-end space-x-2'>
                                                                <Button onClick={() => { saveRegistration(eventRegistration) }} icon="pi pi-save" className='p-button-success mr-2'></Button>
                                                                <Button onClick={() => { deleteRegistration(eventRegistration) }} icon="pi pi-times" className='p-button-danger'></Button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <Button label={t('event.component.addParticipant')} onClick={() => { addRegistration(RaceEntryType.Contestant) }} className='grow-0'></Button>
                            </Panel>
                        </TabPanel>
                    }
                    {
                        event.id > 0 &&
                        <TabPanel header={t('event.component.marks')}>
                            {/* marks */}
                            <Panel header={t('event.component.marks')}>
                                <Table>
                                    <thead>
                                        <TableHeadRow>
                                            <TableHeadCell>Id</TableHeadCell>
                                            <TableHeadCell>{t('common.name')}</TableHeadCell>
                                            <TableHeadCell>{t('event.component.code')}</TableHeadCell>
                                            <TableHeadCell>{t('event.component.device')}</TableHeadCell>
                                            <TableHeadCell>Sessions</TableHeadCell>
                                            <TableHeadCell></TableHeadCell>
                                        </TableHeadRow>
                                    </thead>
                                    <tbody>
                                        {
                                            eventRegistrations &&
                                            eventRegistrations.filter(eventRegistration => eventRegistration.type === RaceEntryType.Boundry).map((eventRegistration) => {
                                                return (
                                                    <TableRow key={eventRegistrations?.indexOf(eventRegistration)}>
                                                        <TableCell>
                                                            {eventRegistration.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            <InputText value={eventRegistration.name}
                                                                onValueChange={(value) => { patchRegistration(eventRegistration, { name: value as string }) }} className='w-full' />
                                                        </TableCell>
                                                        <TableCell>
                                                            <InputText value={eventRegistration.code}
                                                                onValueChange={(value) => { patchRegistration(eventRegistration, { code: value as string }) }} className='w-full' />
                                                        </TableCell>
                                                        <TableCell>
                                                            <InputNumber value={eventRegistration.deviceId ?? 0}
                                                                onValueChange={(value) => { patchRegistration(eventRegistration, { deviceId: value as number }); }} className='w-full' />
                                                        </TableCell>
                                                        <TableCell>
                                                            {/* <Select value={eventRegistration.sessions as any[]} options={event.sessions?.map(session => {
                                                            return { value: session.id, label: session.name };
                                                        }) as SelectOption[]} onChange={(e) => { patchRegistration(eventRegistration, { sessions: Array.from(e.target.selectedOptions, option => parseInt(option.value)) }) }} multiple /> */}

                                                            <MultiSelect value={eventRegistration.sessions as any[]} options={event.sessions?.map(session => {
                                                                return { value: session.id, label: session.name };
                                                            }) as SelectOption[]} onChange={(e) => { patchRegistration(eventRegistration, { sessions: (e as any as MultiSelectChangeParams).value }) }} />

                                                        </TableCell>
                                                        <TableCell className='w-0'>
                                                            <div className='flex justify-end space-x-2'>
                                                                <Button onClick={() => { saveRegistration(eventRegistration) }} icon='pi pi-save' className='mr-2' />
                                                                <Button onClick={() => { deleteRegistration(eventRegistration) }} icon='pi pi-trash' />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <Button label={t('event.component.addMark')} onClick={() => { addRegistration(RaceEntryType.Boundry) }} icon='pi pi-plus'></Button>
                            </Panel>
                        </TabPanel>
                    }

                </TabView>
            </div>
        </Layout>
    )
}

export interface EventSessionsProps {
    sessions: EventSession[] | undefined;
    event: Event;
    registrations: EventEntry[]
}

const EventSessions: FunctionComponent<EventSessionsProps> = (props) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [sessions, setSessions] = useState<EventSession[] | undefined>(props.sessions);
    const [session, setSession] = useState<EventSession | null>(null);
    const [sessionIndex, setSessionIndex] = useState<number>(-1);
    const [dialogIsVisible, setDialogIsVisible] = useState<boolean>(false);

    useEffect(() => {
        dispatch(setEventSessions(sessions));
    }, [sessions, dispatch])

    const deleteSession = (session: EventSession) => {
        const index = sessions?.indexOf(session);

        if (index == null || index < 0) {
            console.error('Event Session not found!');
            return;
        }

        setSessions(sessions?.filter((val, i, arr) => { return i !== index }));
    }

    const showEditModal = (session: EventSession | null) => {
        session = session ?? {
            id: 0,
            name: '',
            startDate: DateTime.now().toString(),
            endDate: DateTime.now().toString()
        };
        setSession(session);
        setSessionIndex(sessions?.indexOf(session) ?? -1);
        setDialogIsVisible(true);
    }

    const actionBodyTemplate = (rawData: EventSession) => {
        const index = sessions?.indexOf(rawData);
        const id = `session_remove_${index}`;

        return (
            <>
                <Button onClick={() => { showEditModal(rawData); }} icon="pi pi-pencil" className='mr-2'></Button>
                <Button id={id} onClick={() => { deleteSession(rawData); }} icon="pi pi-times" className='p-button-danger'
                    showConfirmation={true}>
                </Button>
            </>
        );
    }

    const sessionTableColumns: Column[] = [
        { field: 'id', header: 'Id' },
        { field: 'name', header: t('common.name') },
        { field: 'startDate', header: t('event.component.startDate'), dataType: 'date' },
        { field: 'endDate', header: t('event.component.endDate'), dataType: 'date' },
        { body: actionBodyTemplate },
    ];

    const onDialogHide = (result: boolean) => {
        setDialogIsVisible(false);

        if (!result || session == null) {
            return;
        }

        if (sessionIndex < 0) {
            if (sessions == null) {
                setSessions([session]);
            } else {
                setSessions([...sessions, session]);
            }
            return;
        }


        setSessions(Object.assign([], sessions, { [sessionIndex]: session }));
    }

    const sessionTimelineContent = (item: EventSession) => {

        const startDate = DateTime.fromISO(item?.startDate ?? '');
        const endDate = DateTime.fromISO(item?.endDate ?? '');

        const eventStartDate = DateTime.fromISO(props.event?.startDate ?? '');
        const eventEndDate = DateTime.fromISO(props.event?.endDate ?? '');
        const invalid = !(startDate >= eventStartDate && startDate < eventEndDate && endDate > eventStartDate && endDate <= eventEndDate && startDate < endDate);

        const diff = endDate.diff(startDate, ["years", "months", "days", "hours"]);

        const formatted = Interval
            .fromDateTimes(startDate, endDate)
            .toDuration()
            .valueOf();

        const title = () => {

            return (<div className='flex justify-content-between align-content-center align-items-center mb-4'>

                {item.name}
                <Button onClick={() => { showEditModal(item); }} icon='pi pi-pencil' rounded />

            </div>);

        }

        return (
            <Card title={title} subTitle={Common.formatDateNew(item.startDate) + ' - ' + Common.formatDateNew(item.endDate)} className={`${invalid ? 'bg-red-100' : ''}`}>
                <div className=''>
                    <Label>{t('event.component.eventDuration')}: </Label>
                    {humanizeDuration(formatted, { language: 'gr' })}
                </div>
                <div className='my-4'>Id: {item.id}</div>
                <div className='my-4'>Participants: {(props?.registrations ?? [])?.filter(r => r.type === RaceEntryType.Contestant && r.sessions?.some(s => s === item.id)).length}</div>
                <div className='flex justify-between'>
                    <Button label="Watch" className="p-button-text" onClick={() => { navigate(`/livemap/${props.event.id}/${item.id}`) }}></Button>
                    <Button onClick={() => { deleteSession(item); }} icon='pi pi-trash' rounded className='p-button-danger' />
                </div>
            </Card>
        );
    };

    return (
        <>
            {/* <DataTable data={sessions} columns={sessionTableColumns} className='mb-4'></DataTable> */}

            <Timeline value={sessions ?? []} content={sessionTimelineContent} />

            <Button label={t('event.component.addSession')} className="mt-4" icon='pi pi-plus' onClick={() => showEditModal(null)} />

            {/* add/edit session modal */}
            <Dialog visible={dialogIsVisible} header={t('event.component.addSession')} onHide={(result: boolean) => { onDialogHide(result); }}
                cancelButtonLabel='Cancel' okButtonLabel='OK'>
                <div className='grid'>
                    <div className='col-12'>
                        <InputText value={session?.name} label={t('common.name')}
                            onValueChange={(value) => { setSession({ ...session, name: value as string } as EventSession); }} required />
                    </div>
                    <div className='col-6'>
                        <Calendar value={session?.startDate ?? ''} label={t('event.component.startDate')}
                            onValueChange={(value) => { setSession({ ...session, startDate: value } as EventSession); }}
                            minDateTime={props.event?.startDate} maxDateTime={props.event?.endDate} />
                    </div>
                    <div className='col-6'>
                        <Calendar value={session?.endDate ?? ''} label={t('event.component.endDate')}
                            onValueChange={(value) => { setSession({ ...session, endDate: value } as EventSession); }}
                            minDateTime={session?.startDate} maxDateTime={props.event?.endDate} />
                    </div>
                </div>
            </Dialog>
        </>
    );

}

const mapStateToProps = (state: RootState) => {
    return {
        event: selectEvent(state.events),
        eventRegistrations: selectEventRegistrations(state.events),
    }
}

export default connect(mapStateToProps)(EventEdit)
