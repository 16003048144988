
import { InputText as PrimeInputText } from 'primereact/inputtext';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { IInputControlProps } from '../../interfaces/input-control-props.interface';
import { Common } from '../../lib/common';

export interface InputTextProps extends IInputControlProps {

}

const InputText: FunctionComponent<InputTextProps> = (props) => {

    const id = Common.uniqueId();

    const [value, setValue] = useState(props.value);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [touched, setTouched] = useState<boolean>(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    useEffect(() => {
        if (props.required && touched && Common.isNullOrEmpty(value as string)) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    }, [value, props.required, touched])

    const change = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        setTouched(true);
    }

    return (
        <div className='field'>
            {
                props.label &&
                <label htmlFor={id}>{props.label}</label>
            }
            <PrimeInputText id={id} value={value}
                onChange={(e) => { change(e); }}
                onBlur={() => { props.onValueChange && props.onValueChange(value); }}
                className={'w-full ' + (props.className != null ? props.className : '') + (!isValid ? ' p-invalid' : '')} />
        </div>
    )
}

export default InputText
