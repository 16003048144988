import { Common } from "../lib/common";
import { Event, EventSession, LocationEntry } from "../lib/types";

export function eventFromJson(event: Event): Event {

    return {
        ...event, ...{
            startDate: event.startDate, //event.startDate != null ? Common.toDatetimeLocal(new Date(event.startDate)) : undefined,
            endDate: event.endDate, //event.endDate != null ? Common.toDatetimeLocal(new Date(event.endDate)) : undefined,
            sessions: event.sessions?.map(session => eventSessionFromJson(session))
        }
    };
}

export function eventSessionFromJson(eventSession: EventSession): EventSession {

    return {
        ...eventSession, ...{
            startDate: eventSession.startDate, //eventSession.startDate != null ? Common.toDatetimeLocal(new Date(eventSession.startDate)) : undefined,
            endDate: eventSession.endDate, //eventSession.endDate != null ? Common.toDatetimeLocal(new Date(eventSession.endDate)) : undefined,
        }
    };
}

export function locationEntryFromJson(locationEntry: LocationEntry): LocationEntry {

    const timestamp = new Date(locationEntry.timestamp);

    return {
        ...locationEntry, ...{
            timestamp: Common.toDatetimeLocal(timestamp),
            millis: timestamp.getTime()
        }
    };
}