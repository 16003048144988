import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { DateTime } from "luxon";
import { Event, EventEntry } from '../../lib/types';
import { setEventCountry, setEventEndDate, setEventLatitude, setEventLocation, setEventLongitude, setEventName, setEventSessions, setEventStartDate } from "../actions/eventsActions";
import { fetchEvent, fetchEventRegistrations, fetchEvents } from "../thunks/eventsThunks";

// Define a type for the slice state
export interface EventsState {
    list: Event[],
    event: Event,
    eventRegistrations: EventEntry[]
}

// Define the initial state using that type
const initialState: EventsState = {
    list: [],
    event: {
        id: 0,
        name: '',
        startDate: DateTime.local().toISO(),
        endDate: DateTime.local().plus({ hours: 2 }).toISO(),
        country: '',
        location: '',
        latitude: 0,
        longtitude: 0,
        imagePath: '',
        sessions: []
    },
    eventRegistrations: []
}

export const eventsReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(fetchEvents.fulfilled, (state, action) => {
            state.list = action.payload
        })
        .addCase(fetchEvent.fulfilled, (state, action) => {
            state.event = action.payload
        })
        .addCase(fetchEventRegistrations.fulfilled, (state, action) => {
            state.eventRegistrations = action.payload
        })
        .addCase(setEventName, (state, action) => {
            return produce(state, (draftState) => {
                draftState.event.name = action.payload;
            });
        })
        .addCase(setEventStartDate, (state, action) => {
            return produce(state, (draftState) => {
                draftState.event.startDate = action.payload;
            });
        })
        .addCase(setEventEndDate, (state, action) => {
            return produce(state, (draftState) => {
                draftState.event.endDate = action.payload;
            });
        })
        .addCase(setEventCountry, (state, action) => {
            return produce(state, (draftState) => {
                draftState.event.country = action.payload;
            });
        })
        .addCase(setEventLocation, (state, action) => {
            return produce(state, (draftState) => {
                draftState.event.location = action.payload;
            });
        })
        .addCase(setEventLatitude, (state, action) => {
            return produce(state, (draftState) => {
                draftState.event.latitude = action.payload;
            });
        })
        .addCase(setEventLongitude, (state, action) => {
            return produce(state, (draftState) => {
                draftState.event.longtitude = action.payload;
            });
        })
        .addCase(setEventSessions, (state, action) => {
            return produce(state, (draftState) => {
                draftState.event.sessions = action.payload;
            });
        })
)
