
import { Icon } from 'leaflet';
import React, { FunctionComponent, MutableRefObject, useRef, useState } from 'react';
import { MapContainer, Marker, Polyline, TileLayer, Tooltip, useMap, useMapEvent } from 'react-leaflet';
import BoatMarker from './boat-marker';
import mark from '../../images/mark.svg';
import DotMarker from './dot-marker';

export enum MarkerType {
    Boat,
    Mark
}

export type MapMarker = {
    lat: number,
    long: number,
    type?: MarkerType,
    tooltip?: string,
    color?: string
}

export type MapPolyline = {
    points: Array<[number, number]>, // array of arrays [lat, long]
    color?: string
}

export interface MapProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
    center: { latitude: number, longitude: number };
    markers?: MapMarker[];
    polylines?: MapPolyline[];
    autoCenter?: boolean;
}

export interface MapCenterProps {
    latestMapRecenter: MutableRefObject<number>;
    markers?: MapMarker[];
}

function MapCenter(props: MapCenterProps) {
    const map = useMap();

    // todo: make this smarter, check if most of the moving objects is inside viewport
    if (new Date().getTime() - props.latestMapRecenter?.current < 4000) {
        return null;
    }

    props.latestMapRecenter.current = new Date().getTime();

    if (props.markers?.length == null || props.markers?.length <= 0) {
        return null;
    }

    const latSum = (props.markers ?? [])?.map(m => m.lat).reduce((a, b) => a + b, 0);
    const latAvg = latSum / props.markers?.length;
    const longSum = (props.markers ?? [])?.map(m => m.long).reduce((a, b) => a + b, 0);
    const longAvg = longSum / props.markers?.length;

    map.setView([latAvg, longAvg]);

    return null;
}

function SetAutoCenterOnClick({ recenterRef }: any) {
    const map = useMapEvent('click', (e) => {
        recenterRef.current = !recenterRef.current;
    });

    return null;
}

const Map: FunctionComponent<MapProps> = (props) => {

    const recenterRef = useRef(props.autoCenter);
    const latestMapRecenter = useRef(new Date().getTime());

    const [currentTrack, setCurrentTrack] = useState({});

    return (
        <MapContainer center={[props.center.latitude, props.center.longitude]} zoom={15}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <SetAutoCenterOnClick recenterRef={recenterRef} />
            {
                recenterRef.current &&
                <MapCenter markers={props.markers?.filter(marker => marker.type !== MarkerType.Mark)} latestMapRecenter={latestMapRecenter} />
            }
            {
                props.markers &&
                props.markers.map((marker) => {
                    const index = props.markers?.indexOf(marker);

                    // if (marker.type === MarkerType.Boat) {
                    //     return (<BoatMarker key={index} props={{ 
                    //         lat: marker.lat, 
                    //         lng: marker.long, 
                    //         children: <Tooltip>{marker.tooltip}</Tooltip>,
                    //         color: marker.color
                    //      }} />);
                    // }
                    
                    if (marker.type === MarkerType.Boat) {
                        return (<DotMarker key={index} 
                            icon={new Icon({ iconUrl: mark, iconSize: [24, 24], iconAnchor: [10, 20] })}
                            lat={marker.lat} lng={marker.long} color={marker.color}></DotMarker>);
                    }

                    return (
                        <Marker key={index} 
                        position={[marker.lat, marker.long]} 
                        icon={new Icon({ iconUrl: mark, iconSize: [24, 24], iconAnchor: [10, 20] })}>
                            <Tooltip>{marker.tooltip}</Tooltip>
                        </Marker>
                    );
                })
            }

            {
                props.polylines &&
                props.polylines.map((polyline) => {
                    const index = props.polylines?.indexOf(polyline);
                    return (
                        <Polyline key={index} pathOptions={{ color: polyline.color }} positions={polyline.points} />
                    );
                })
            }

        </MapContainer>
    )
}

export default Map
