import fetchJson from "../lib/fetch-json";
import { Event, EventEntry, LocationEntry } from "../lib/types";
import { eventToJson } from "./request-mappers";
import { eventFromJson, locationEntryFromJson } from "./response-mappers";

const baseUrl = `${process.env.REACT_APP_SERVER}/api/Event`;

// Event
export async function getEvents(): Promise<Event[]> {
    const res = await fetchJson(`${baseUrl}/all`);

    return res.map((event: Event) => eventFromJson(event));
}

export async function getEvent(id: number): Promise<Event> {
    const res = await fetchJson(`${baseUrl}/${id}`);

    return eventFromJson(res);
}

export async function addEvent(event: Event): Promise<Event> {
    const res = await fetchJson(`${baseUrl}`, {
        method: 'POST',
        body: JSON.stringify(eventToJson(event))
    });

    return eventFromJson(res);
}

export async function updateEvent(event: Event): Promise<Event> {
    const res = await fetchJson(`${baseUrl}/${event.id}`, {
        method: 'PUT',
        body: JSON.stringify(eventToJson(event))
    });

    return eventFromJson(res);
}

// EventEntry
export async function getEventRegistrations(eventId: number): Promise<EventEntry[]> {
    const res = await fetchJson(`${baseUrl}/registration/${eventId}`);

    return res;
}

export async function addEventRegistration(eventEntry: EventEntry, eventId: number): Promise<EventEntry> {
    const res = await fetchJson(`${baseUrl}/registration/${eventId}`, {
        method: 'POST',
        body: JSON.stringify(eventEntry)
    });

    return res;
}

export async function updateEventRegistration(eventEntry: EventEntry): Promise<EventEntry> {
    const res = await fetchJson(`${baseUrl}/registration/${eventEntry.id}`, {
        method: 'PUT',
        body: JSON.stringify(eventEntry)
    });

    return res;
}

export async function deleteEventRegistration(eventEntryId: number): Promise<void> {
    const res = await fetchJson(`${baseUrl}/registration/${eventEntryId}`, {
        method: 'DELETE'
    });

    return res;
}

// LocationEntry
export async function getLocationEntries(sessionId: number, offset?: number, backOffset?: number): Promise<LocationEntry[]> {

    offset = offset ?? 0;
    backOffset = backOffset ?? 0;

    const res: LocationEntry[] = await fetchJson(`${baseUrl}/entries/${sessionId}/${offset}/${backOffset}`);

    return res.map(locationEntry => locationEntryFromJson(locationEntry));
}


