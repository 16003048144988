
import React, { FunctionComponent } from 'react';

export interface LabelProps extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {

}

const Label: FunctionComponent<LabelProps> = (props) => {

    return (
        <label {...props}>{props.children}</label>
    )
}

export default Label
